import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { graphql, navigate } from "gatsby";
import { SettingsStore, SettingsShape } from "src/stores/Settings";
import { PageStore } from "src/stores/Page";

import styled, { css, keyframes } from "styled-components";
import Layout from "src/layouts/Main";
import { colors, mediaquery, icons, spacing } from "src/styles/variables";
import { Container, Row, Col } from "react-awesome-styled-grid";
import { HeaderM, SubHeaderM } from "src/atoms/Typography";
import HeaderCurved from "src/atoms/HeaderCurved/HeaderCurved";
import Share from "src/molecules/Share";
import Close from "src/atoms/Vectors/Standard/Close";
import DonutProgressBar from "src/atoms/DonutProgressBar/DonutProgressBar";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";
import RichText from "src/atoms/RichText";
import CardCarousel from "src/molecules/CardCarousel";
import TrackerCategories from "src/molecules/Tracker/TrackerCategories";
import trackEvent from "./trackEvent";

const TrackerResultsCategoryPage = ({
  data,
  pageContext: { locale, localizedSlugs },
  path,
  location,
}) => {
  if (!data) {
    throw new Error(`Page query failed: reason unknown`);
  }

  const settings = {
    ...data.contentfulSettings,
    locale,
    localizedSlugs,
  };
  const trackerResultsCategoryScoreTitle = settings.translations.filter(
    (translation) => translation.key === "trackerResultsCategoryScoreTitle"
  );
  const closeTitle = settings.translations.filter(
    (translation) => translation.key === "closeTitle"
  );
  const trackerResultCategory = data.contentfulTracker.nodes[0];
  const { categories, shareModule } = trackerResultCategory;

  const [trackerResultsCategory, setTrackerResultsCategory] = useState(null);
  const [trackerSlug, setTrackerSlug] = useState(null);

  const getTrackerResultsCategory = () => {
    const { trackerUrl } = (history && history.state) || {};
    setTrackerSlug(trackerUrl);
    const searchParams = new URLSearchParams(
      window.location.search.split("/")[0]
    );
    const categoryResultsCategory = [];
    for (const param of searchParams) {
      categoryResultsCategory.push(param);
    }
    const filteredCategory = categories.filter(
      (category) => category.id === categoryResultsCategory[0][0]
    );
    filteredCategory[0].score = categoryResultsCategory[0][1];
    setTrackerResultsCategory(filteredCategory);
  };

  const getScoreIndex = () => {
    const score = trackerResultsCategory?.[0].score;
    const scores = trackerResultsCategory?.[0].scores.findIndex((item) => {
      return score >= item.scoreStart && score <= item.scoreEnd;
    });
    return scores;
  };

  useEffect(() => {
    getTrackerResultsCategory();
  }, []);

  const goToPrevPage = (e) => {
    e.preventDefault();

    const { navigationHistory, params } = location.state;
    navigationHistory.splice(-1, 1); // remove current page from history

    if (!navigationHistory.length) {
      navigate(`/${params || ""}`, { state: { navigationHistory: ["/"] } });
    } else {
      const to = `${navigationHistory[navigationHistory.length - 1]}${
        params || ""
      }`;
      navigate(to, {
        state: {
          navigationHistory,
          trackerUrl: trackerSlug,
        },
      });
    }
  };

  useEffect(() => {
    if (trackerResultsCategory) {
      window.tc_vars = {
        country: locale.currentRegion.region,
        environment: process.env.GATSBY_TRACKING_ENV,
        page_category: "Tracker Result Category",
        page_name: `${trackerResultsCategory[0].title} Tracker Result Category`,
        page_type: "Tracker Result Category Page",
        page: path,
        work_env: process.env.GATSBY_TRACKING_ENV,
        brand: "Activia",
      };
      window.tC.container.reload({ events: { page_view: [{}, {}] } });

      trackEvent();
    }
  }, [trackerResultsCategory?.[0].title]);

  return (
    <SettingsStore value={settings}>
      <PageStore>
        <Layout header locale={locale}>
          <HeaderCurved
            fixedHeight={false}
            backgroundColor={
              trackerResultsCategory?.[0].colour
                ? trackerResultsCategory[0].colour
                : colors.activiaGreen
            }
          >
            <ContainerWrapper>
              <ContainerAnimation>
                <NoMarginRowStyled>
                  <ProgressColStyled md={10} offset={{ md: 3 }}>
                    <ResultsHeader as="h1">
                      {trackerResultsCategoryScoreTitle[0].value.replace(
                        "{0}",
                        trackerResultsCategory?.[0].title
                      )}
                    </ResultsHeader>
                    <DonutProgressBarStyled
                      strokeColor={colors.white}
                      percent={trackerResultsCategory?.[0].score}
                    />
                    {shareModule && (
                      <ShareWrapper>
                        <Share
                          title={shareModule.title}
                          twitterCopy={shareModule.twitterText}
                          url={trackerSlug}
                        />
                      </ShareWrapper>
                    )}
                  </ProgressColStyled>
                </NoMarginRowStyled>
              </ContainerAnimation>
            </ContainerWrapper>
          </HeaderCurved>

          <ContainerStyles>
            <CloseButton onClick={goToPrevPage} title={closeTitle} />
            {trackerResultsCategory &&
              trackerResultsCategory?.[0].scores
                .filter((_, itemIndex) => itemIndex === getScoreIndex())
                .map((item) => {
                  return (
                    <Container
                      key={`tracker_results_category_component_${item.id}`}
                    >
                      <NoMarginRowStyled>
                        <Col md={10} offset={{ md: 3 }}>
                          <RichTextWapper>
                            <RichText
                              as="div"
                              enableRenderer
                              doc={item.adviceTitle.value}
                              alignment={"left"}
                            />
                          </RichTextWapper>
                        </Col>
                      </NoMarginRowStyled>
                      {item.components &&
                        item.components.map((subItem, index) => {
                          return (
                            <NoMarginRowStyled>
                              <Col md={10} offset={{ md: 3 }}>
                                {subItem.__typename ===
                                "ContentfulCardCarousel" ? (
                                  <CardCarouselWrapper
                                    key={`tracker_results_category_component_cardCarousel_${index}`}
                                  >
                                    <CardCarouselHeader as="h5">
                                      {subItem.title}
                                    </CardCarouselHeader>
                                    <CardCarousel cards={subItem?.cards} />
                                  </CardCarouselWrapper>
                                ) : null}
                                {subItem.__typename ===
                                "ContentfulTextModule" ? (
                                  <RichTextWapper
                                    key={`tracker_results_category_component_textModule_${index}`}
                                  >
                                    <RichText
                                      as="div"
                                      enableRenderer
                                      doc={subItem.value}
                                      alignment={"left"}
                                    />
                                  </RichTextWapper>
                                ) : null}
                              </Col>
                            </NoMarginRowStyled>
                          );
                        })}
                    </Container>
                  );
                })}
          </ContainerStyles>
        </Layout>
      </PageStore>
    </SettingsStore>
  );
};

const CloseButton = ({ onClick, isHeaderVisible, title }) => {
  return (
    <StickyContainer isHeaderVisible={isHeaderVisible}>
      <Row>
        <Col xs={8} md={14}>
          <CloseLinkOuter>
            <a onClick={onClick} title={title}>
              <FloatingButton size={SIZES.medium}>
                <Close size={icons.m} />
              </FloatingButton>
            </a>
          </CloseLinkOuter>
        </Col>
      </Row>
    </StickyContainer>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isHeaderVisible: PropTypes.bool,
  title: PropTypes.string,
};

TrackerResultsCategoryPage.propTypes = {
  data: PropTypes.shape({
    contentfulSettings: PropTypes.shape(SettingsShape).isRequired,
    contentfulTracker: PropTypes.shape({
      //seo: PropTypes.object.isRequired,
      //hint: PropTypes.string,
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          categories: PropTypes.arrayOf(TrackerCategories).isRequired,
          shareModule: PropTypes.object,
        })
      ),
    }),
  }),
  pageContext: PropTypes.shape({
    locale: PropTypes.object.isRequired,
    localizedSlugs: PropTypes.instanceOf(Array),
  }).isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      navigationHistory: PropTypes.array,
      params: PropTypes.string,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query getTrackerCategory($nodeLocale: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...SettingsFields
    }

    contentfulTracker: allContentfulTracker(
      limit: 1
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        categories {
          ...TrackerCategory
        }
        shareModule {
          ...ShareOptions
        }
      }
    }
  }
`;

const NoMarginRowStyled = styled(Row)``;

const ProgressColStyled = styled(Col)`
  align-items: center;
`;

const StickyContainer = styled(Container)`
  position: sticky;
  top: ${({ isHeaderVisible }) => (isHeaderVisible ? "100px" : "50px")};
  z-index: 1;
`;

const CloseLinkOuter = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    position: relative;
    top: -6rem;

    ${mediaquery.sm(css`
      top: -7rem;
    `)}

    ${mediaquery.lg(css`
      top: -125px;
    `)}

    ${mediaquery.xl(css`
      top: -145px;
    `)}
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeInAnimation = css`
  animation: 1s ${fadeIn} cubic-bezier(0.47, 0, 0.37, 1) forwards;
`;

const ContainerAnimation = styled.div`
  ${fadeInAnimation};
`;

const ResultsHeader = styled(HeaderM)`
  padding: 2rem 0;
`;

const ContainerWrapper = styled(Container)`
  color: ${colors.white};
  text-align: center;
  padding-top: calc(100px - 2rem);
`;

const ContainerStyles = styled.div`
  margin-top: 4rem;
`;

const ShareWrapper = styled.div`
  position: relative;

  ul {
    justify-content: center;

    li {
      background-color: ${colors.white};

      &:last-of-type {
        path {
          fill: none;
          stroke: ${colors.activiaGreen};
        }
      }
    }

    path {
      fill: ${colors.activiaGreen};
      stroke: none;
    }

    div {
      color: initial;
    }
  }
`;

const RichTextWapper = styled.div`
  margin-bottom: 2rem;
`;

const CardCarouselWrapper = styled.div`
  margin-bottom: 2rem;
`;

const DonutProgressBarStyled = styled(DonutProgressBar)`
  width: 18rem;

  ${mediaquery.md(css`
    width: 28rem;
  `)}

  .donut-text text {
    transform: translate(0, 3px);
  }
`;

const CardCarouselHeader = styled(SubHeaderM)`
  color: ${colors.activiaGreen};
  margin: ${spacing.stack.md};
`;

export default TrackerResultsCategoryPage;
